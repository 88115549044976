.adminMenu .ant-menu-submenu-arrow {
  color: white !important;
}
.adminMenu .ant-menu-submenu-title:hover,
.adminMenu .ant-menu-item:not(.ant-menu-item-selected):hover,
.adminMenu .ant-menu-submenu-selected > .ant-menu-submenu-title {
  background-color: rgba(0, 0, 0, 0.2) !important;
}

.adminMenu > li:last-child {
  position: absolute !important;
  bottom: 20px;
  background-color: #e6f4ff;
  display: flex !important;
}
.adminMenu
  > li:last-child.ant-menu-item:not(.ant-menu-item-selected):hover
  span,
.adminMenu
  > li:last-child.ant-menu-item:not(.ant-menu-item-selected):hover
  span
  p {
  transition: all 300ms !important;
  color: white !important;
}
