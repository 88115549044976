.filterButton {
  background-color: var(--blue_primary) !important;
  color: white !important;
  border: 1px solid var(--blue_primary) !important;
}
.sorting .ant-select-selector,
.sorting .ant-select-selector:hover,
.sorting {
  box-shadow: none !important;
  border-color: var(--blue_primary) !important;
}
