.adminProductsTable .ant-table-row td {
  max-width: 250px !important;
}

.productDeleteModal {
  width: 100rem !important;
}
.deleteButton {
  border: none !important;
}
.deleteButton:hover {
  background-color: rgb(207, 54, 54) !important;
  color: white !important;
  border-color: transparent !important;
  border: none !important;
}
.editModal {
  top: 10px !important;
  width: 90vw !important;
}
