@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
}
*,
*::after,
*::before {
  box-sizing: inherit;
}
html {
  box-sizing: border-box;
  font-size: 62.5%;
  overflow-x: hidden;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
:root {
  --border-radius: 8px;
  --header-main-color_dark: #2f6d87;
  --header-main-color_lighter: #62aed4;
  --blue_primary: #333399;
}
body {
  width: 100% !important;
  overflow-y: visible !important;
}

.menu {
  border: none !important;
}
.ant-drawer-body {
  padding: 0 !important;
}
.ant-drawer-body .ant-menu-item {
  padding: 0 20px !important;
  margin-inline: 0 !important;
}
.ant-drawer-content-wrapper {
  height: max-content !important;
}
.menu .ant-menu-item-active {
  color: var(--blue_primary) !important;
}
.menu .ant-menu-item-selected,
.menu .ant-menu-submenu-selected > .ant-menu-submenu-title {
  color: var(--blue_primary) !important;
}
.menu .ant-menu-item-active::after,
.menu .ant-menu-submenu-active::after {
  border-color: var(--blue_primary) !important;
}
.menu .ant-menu-item-selected::after,
.menu .ant-menu-submenu-selected::after {
  /* bottom: 0 !important; */
  /* transform: translateY(10px); */
  border-color: var(--blue_primary) !important;
}

.menu .ant-menu-submenu .ant-menu-item-selected {
  color: white !important;
  background-color: var(--blue_primary) !important;
}
.searchButton .ant-input-search-button {
  background-color: var(--blue_primary) !important;
}
.searchButton.ant-input-search .ant-input:focus,
.searchButton.ant-input-search .ant-input:hover {
  border-color: var(--blue_primary) !important;
  box-shadow: none !important;
}
a {
  transition: none !important;
}
.generalAdminButton {
  background-color: var(--blue_primary);
  padding: 0 2.5rem;
  height: 4rem;
  color: white;
  border: none !important;
}
.generalAdminButton:hover {
  border: none !important;
  color: white !important;
  background-color: var(--blue_primary) !important;
  border-color: transparent !important;
}
/* src/Carousel.css */
.carousel {
  position: relative;
  max-width: 100%;
  height: 100%;
  overflow: hidden;
}
.carousel__btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  font-size: 2rem;
  border: none;
  padding: 5px;
  cursor: pointer;
  z-index: 1;
  border-radius: 2px;
  transition: background-color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
}
.carousel__btn:hover {
  background-color: rgba(0, 0, 0, 0.8);
}
.carousel__btn--prev {
  left: 20px;
}
.carousel__btn--next {
  right: 20px;
}
.carousel__img {
  width: 100% !important;
  height: 100% !important;
  display: block;
  transition: transform 0.3s ease-in-out;
}
.carousel__img:hover {
  transform: scale(1.05);
}
.carousel__indicator {
  background-color: black;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.registerModal .ant-modal-content {
  padding: 0 !important;
}
.formInput [disabled] {
  opacity: 1 !important;
}
.formInput.ant-input-outlined.ant-input-disabled input[disabled] {
  background-color: transparent !important;
}
.formInput,
.formLabel .ant-select-selector {
  height: 40px;
  border-radius: 14px;
  /* border: none !important; */
  /* background-color: rgba(247, 247, 247, 1) !important; */
}
.formInput:focus,
.formInput .ant-input-number:focus {
  box-shadow: none !important;
}
.formLabel {
  margin-bottom: 5px !important;
}
.formLabel .ant-select-selector {
  height: 50px;
  box-shadow: none !important;
}
.ant-form-item-label {
  padding: 0 !important;
}
.ant-input-outlined:hover,
.ant-input-outlined:focus,
.ant-input-outlined:focus-within {
  border-color: var(--blue_primary) !important;
} /* Hide arrows in Chrome, Safari, and Edge */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.formLabel .ant-form-item-label > label {
  height: max-content;
  padding: 5px 0;
}
/* Hide arrows in Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

@media screen and (max-width: 1060px) {
  .randomProducts {
    flex-wrap: wrap !important;
  }
}

@media screen and (max-width: 400px) {
  .courselIndicator {
    width: 90% !important;
  }
}
.ant-image-preview-img {
  padding: 0 10px;
}
.searchButton .ant-btn-icon {
  color: white;
}
