.addRemoveCategoriesForm .ant-form-item-label > label {
  font-size: 16px;
  min-width: 180px; /* Change the font size as needed */
}

.addProductSpinner.ant-spin-fullscreen {
  gap: 15px;
  background-color: rgba(0, 0, 0, 0.65);
}
.addProductSpinner.ant-spin-fullscreen svg {
  color: var(--header-main-color_dark);
  transform: scale(1.5) !important;
}
.addProductSpinner.ant-spin-fullscreen .ant-spin-text {
  font-size: 16px !important;
}
.removeCategoryModal {
  width: 70rem !important;
  top: 300px;
}
.disabledSelect .ant-select-item-option-disabled {
  color: black !important;
}
